
@font-face {
	font-family: 'Satoshi';
	src: local('Satoshi'), url('../fonts/Satoshi-Variable.ttf') format("truetype");
}

@font-face {
	font-family: 'AlacritySans';
	src: local('AlacritySans'), url('../fonts/Alacrity-Sans/Alacrity-Sans-Regular.ttf') format("truetype");
}

@font-face {
	font-family: 'PlusJakartaSans';
	src: local('PlusJakartaSans'), url('../fonts/Plus-Jakarta-Sans/PlusJakartaSans-Bold.ttf') format("truetype");
}

@font-face {
	font-family: 'fontello';
	src: url('../fonts/asterisk/asterisk.eot');
	src: url('../fonts/asterisk/asterisk.eot') format('embedded-opentype'),
	     url('../fonts/asterisk/asterisk.woff2') format('woff2'),
	     url('../fonts/asterisk/asterisk.woff') format('woff'),
	     url('../fonts/asterisk/asterisk.ttf') format('truetype'),
	     url('../fonts/asterisk/asterisk.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}